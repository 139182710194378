<template>
 <div>
        <v-row dense>
           <v-toolbar>
               <span class="mt-2">{{$moment(selected_draw.date).format("MMM DD, YYYY")}} {{selected_draw.time}}</span>
                 <v-spacer/>
                 <span style="width: 140px" >
                    <v-select
                      dense
                      v-model="selected_draw"
                      outlined
                      label="Draw Time"
                      item-text="time"
                      hide-details
                      :items="OPEN_DRAWS"
                      return-object
                    />
                 </span>
           </v-toolbar>   
           <v-col cols="12">
              <div id="demoPlayer"></div>
            
                <!-- <v-alert
                  dense
                  outlined
                  type="error"
                  >
                   Live draws is not yet available! Dont Bet!
                  </v-alert> -->
           </v-col>
            <v-col cols="12" class="pa-3">
              <!-- <v-row no-gutters class="text-primary"   v-if="OPEN_DRAWS.length>0">
               
              </v-row> -->
              <v-row  v-if="$store.state.watchdraw==true">
                <v-col cols="12" class="pa-2">
                  <v-btn color="success" x-large @click="setOpenMachine(true)" block> <v-icon color="error" left>mdi-play-circle</v-icon> watch Draw</v-btn>
                </v-col>
              </v-row>
                  <table class="va-table print_table3 mt-2 text-left" cellspacing="0">
                      <tr>
                          <td class="pa-1 md-font " style="background: #eaeaea;">COMBINATION</td>
                          <td class="text-center pa-1 md-font" style="background: #eaeaea;"><v-btn x-small tile color="error">BETTING:</v-btn><v-btn x-small tile dark :color="LIVE_DRAW_STATUS=='open'?'success':'#232323'">{{LIVE_DRAW_STATUS}}</v-btn></td>
                          <td class="text-right pa-1 md-font " style="background: #eaeaea;">AMOUNT</td>
                      </tr>
                      <tr v-for="(item, i) in Lines" :key="i">
                          <td><v-icon left small color="error" @click="removeline(i)">mdi-close</v-icon> {{item.num1}} - {{item.num2}}</td>
                          <!-- <td class="text-center">{{item.line_type}}</td> -->
                          <td class="text-right" colspan="2">{{$money(item.line_amount)}}</td>
                      </tr>
                  </table>
                  <br />
                  
                  <v-row no-gutters class="text-primary">
                    <p class="sm-font" :class="BALANCE<1?'text-error':'text-info'">UPDATING BALANCE: {{$money(BALANCE)}}</p>
                    <v-spacer/>
                    <h4>TOTAL:  {{$money(TOTAL)}}</h4>
                  </v-row>
                 
            </v-col>
            <v-col cols="12" class="text-right"  v-if="OPEN_DRAWS.length > 0">
              <v-btn x-small tile color="info" class="mr-4" @click="ticketpop = true">View Tickets ({{tickets.length}})</v-btn></v-col>
        </v-row>
        <template v-if="OPEN_DRAWS.length > 0">
                  <v-bottom-navigation  grow class="elevation-0 mt-5">
                    <v-btn value="clear" @click="clear()">
                      <span class="text-error">CLEAR</span>
                      <v-icon  color="error">mdi-cancel</v-icon>
                    </v-btn>

                    <v-btn @click="numpad=true">
                      <span class="text-secondary">ADD BET</span>
                      <v-icon color="secondary">mdi-plus</v-icon>
                    </v-btn>

                    <v-btn  @click="ValidateTicket()">
                      <span class="text-success">PROCEED</span>
                      <v-icon color="success">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-bottom-navigation>
        </template>
        <template v-else>
          <v-alert
                  dense
                  outlined
                  type="info"
                  >
                    No open draws at the moment!
                  </v-alert>
        </template>
         <v-row>
            <v-col cols="12" class="mt-8">
              <div class="pa-1">RESULTS TREND</div> 
              <div class="regla_container">
                <div class="regla_wrap">
                  <template dark v-for="(item, index) in results">
                    <div class="regla" :key="index">
                    <v-btn color="primary" class="mb-1" fab small>{{item.num1}}</v-btn>
                    <v-btn color="primary" class="mb-1" fab small>{{item.num2}}</v-btn>
                    </div>
                  </template>
                </div>
              </div>
            </v-col>
         </v-row>
      <va-numpad :show="numpad" @DialogEvent="numpadEvent"/>
        <va-ticket-preview :show="preview" :lines="validated_lines" :ticket="ticket" @DialogEvent="previewEvent"/>
         <va-ticket :show="ticket_dailog" :ticket="ticket" @DialogEvent="ticketEvent"/>
          <va-ticket-pop :show="ticketpop" :tickets="tickets" @DialogEvent="tpEvent"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      drform: false,
      numpad: false,
      preview: false,
      ticket_dailog: false,
      selected_draw:{},
      Lines:[],
      results:[],
      validated_lines:[],
      ticket:{},
      ticketpop: false,
      tickets:[]
    }),
    created() {
      this.PING()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.GET_OPEN_GAMES("2DLOCAL")
          this.CHECK_BALANCE(this.user.id)
           this.get_regla()
          setTimeout(()=>{
            this.get_ticketByDraw()
            this.selected_draw = this.$store.state.open_live_draws[0]
          }, 500)
              this.$loadScript("https://cdn.jwplayer.com/libraries/18vZzqkP.js")
    .then(() => {
         // window.jwplayer.key="PBRiwSkdNZ96Bwg/nCU6D0J98lh8dmmuHY989g==";
           setTimeout(()=>{this.EXTERNAL_LOAD_MEET()}, 800)
    })
    .catch(() => {
      // Failed to fetch script
    })
        }
    },
    computed:{
       user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      OPEN_DRAWS() {
       return this.$store.state.open_live_draws.length > 0 ? this.$store.state.open_live_draws:[]
      },
      TOTAL() {
           return this.Lines.reduce((res,item)=>res += this.$IsNum(item.line_amount), 0)
      },
      BALANCE(){
        return  this.$store.state.balance.balance - this.TOTAL
      },
       RESULTS(){
         console.log(this.$store.state.results)
         var resdata = this.$store.state.results.reduce((res,item)=>{
            if(item.game == "2DLOCAL") {
               res[item]
            }
            return res
         }, [])
        return  resdata
      },
      LIVE_DRAW_STATUS(){
        return this.$store.state.live_draw_status
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', "setOpenMachine"]),
    tpEvent(){
      this.ticketpop = false
    },
      previewEvent(e){
        this.preview = false
        if(e != 'cancel') {
          if(e.status) {
            this.ticket = e.ticket
            this.ticket_dailog = true
            this.CHECK_BALANCE(this.user.id)
          } else {
              this.VA_ALERT("error", e.message)
          }
        }
      },
      EXTERNAL_LOAD_MEET() {
          // this.APPLOADER(true, "Checking live feed!")
             window.jwplayer('demoPlayer').setup({
                      file: 'https://whoa3.streams.ph:5443/LiveApp/streams/915080106090001486419705.m3u8',
                      image: '',
                      width: '100%',
                      primary: 'html5',
                      controls: true,
                      aspectratio: '16:9',
                      autostart: 'true'
              });
          
            window.jwplayer().onError(()=> { 
               this.VA_ALERT('warning', "No available live feed at the moment!")
               });
      },
      ticketEvent(e){
        this.ticket_dailog = false
        if(e=="done"){
            this.clear()
        }
        
      },
      ValidateTicket() {
        if(this.Lines.length == 0) {
           this.VA_ALERT("error", "Select your combination!")
          return 
        }
      
        var ticket = {}
        ticket.Lines = this.Lines
        ticket.amount = this.TOTAL.toString()
        ticket.game="2DLOCAL"
        ticket.draw_date = this.selected_draw.date + " " + this.selected_draw.time
        ticket.draw_id = this.selected_draw.id
        this.ValidateNewTicket(ticket)
      },
      clear(){
        this.Lines=[]
          this.ticket={}
      },
      ValidateNewTicket(ticket){
        this.validated_lines=[]
   
         this.$http.post("ticket/validate_new", ticket).then(response => {
                 console.log(response.data)
                if( response.data.status) {
                  this.validated_lines = response.data.newlines
                   this.ticket = ticket
                  this.preview = true
                } else {
                  this.VA_ALERT('error', response.data.message )
                }
               }).catch(e => {
                console.log(e.data)
            })
         console.log(ticket)
      },
      get_ticketByDraw(){
         this.$http.post("ticket/get_by_draw", {draw_id: this.OPEN_DRAWS[0].id, account: this.user.id}).then(response => {
                response.data.tickets!=null?this.tickets =  response.data.tickets:this.tickets=[]
               }).catch(e => {
                console.log(e.data)
            })
      },
       get_regla(){
         this.$http.get("report/get_regla").then(response => {
                response.data.results!=null?this.results =  response.data.results:this.results=[]
               }).catch(e => {
                console.log(e.data)
            })
      },
      removeline(i){
        this.Lines.splice(i,1)
      },
      numpadEvent(data){
        console.log(data)
        console.log(this.Lines)
        this.numpad = false
        if(data != "cancel"){
          this.Lines.push(data)
        }
        console.log(this.Lines)
      }
    },
  }
</script>